:root {
  --yellow: #fbcd01;
  --blue: #00042d;
  --green: #526f4e;
  --brown: #a16559;
  --white: #ffffff;

  --border-radius: 16px;
  --border-radius-sm: 8px;
  --border-radius-xs: 4px;

  --font-family-title: "melange", sans-serif;
  --font-family-body: "Karla", sans-serif;
}

$breakpoint-mobile: 500px;
$breakpoint-tablet-portrait: 834px;
$breakpoint-tablet-landscape: 1194px;

#root {
  background-color: var(--yellow);
  background-image: url("./circles.svg");
  background-repeat: no-repeat;
  background-position: top right -300px;

  @media screen and (max-width: $breakpoint-tablet-landscape) {
    background-position: top right -700px;
  }

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    background-position: top right -1000px;
  }

  @media screen and (max-width: $breakpoint-mobile) {
    background-size: 150%;
    background-position: top 130px right -360px;
  }
}

@font-face {
  font-family: "melange";
  font-style: normal;
  font-weight: 600;
  src: url(./melange-semibold.woff2) format("woff2");
}

@font-face {
  font-family: "melange";
  font-style: normal;
  font-weight: 800;
  src: url(./melange-bold.woff) format("woff");
}

.App {
  color: var(--blue);
  font-family: var(--font-family-body);
  font-size: 18px;
  line-height: 24px;
  margin: 0 auto;
}

/* BANNER */
.banner {
  padding: 4px;
  background-color: var(--brown);
  color: var(--white);
  text-align: center;
  font-size: 14px;
}

.banner-link,
.banner-link:visited {
  text-decoration: none;
  color: var(--yellow);
}

.banner-link:hover,
.banner-link:active {
  color: var(--white);
}

/*  HEADER */
.header {
  padding: 30px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid var(--blue);
  margin-bottom: 40px;
}

.logo-title {
  display: flex;
  align-items: center;
}

.logo {
  background-image: url("./logo.svg");
  background-repeat: no-repeat;
  background-position: center;
  height: 70px;
  width: 100px;
  display: inline-block;
  margin-right: 20px;

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    width: 96px;
  }
}

.title {
  font-size: 18px;
  line-height: 23px;
  width: 250px;
  display: inline-block;
  margin: 0;

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    display: none;
  }
}

.buttons {
  display: flex;
}

.button-link {
  text-decoration: none;
  border-radius: var(--border-radius-sm);
  border: 1px solid var(--blue);
  color: var(--blue);
}

.button-link--linkedin {
  padding: 8px 20px;
  margin-right: 10px;
  width: 18px;
  background-image: url("./logo-linkedin.svg");
  background-repeat: no-repeat;
  background-size: 18px;
  background-position: center;
}

.button-link--linkedin:hover {
  border-color: var(--white);
  background-image: url("./logo-linkedin-white.svg");
}

.button-link--contact {
  background-color: var(--blue);
  color: var(--white);
  padding: 8px 16px;
  border-color: var(--blue);
}

.button-link--contact:hover {
  background-color: var(--white);
  color: var(--blue);
  border-color: var(--white);
}

/* Hero  */
.hero-section + .max-width {
  padding-top: 0;
}

.hello {
  font-family: var(--font-family-title);
  font-size: 120px;
  line-height: 120px;
  margin: 0 0 32px 0;
  font-weight: 700;

  @media screen and (max-width: $breakpoint-mobile) {
    font-size: 70px;
    line-height: 72px;
    margin-bottom: 12px;
    margin-top: 6px;
  }
}

.intro {
  font-family: var(--font-family-title);
  font-size: 90px;
  line-height: 100px;
  margin: 0;

  @media screen and (max-width: $breakpoint-mobile) {
    font-size: 30px;
    line-height: 30px;
  }
}

.highlight {
  background-color: var(--blue);
  color: var(--white);
  padding: 8px;
  border-radius: var(--border-radius-xs);
  line-height: 110px;
  margin: 0;

  @media screen and (max-width: $breakpoint-mobile) {
    line-height: 50px;
  }
}

/* ABOUT */
.value-prop-section {
  display: flex;
  gap: 32px;
  margin-bottom: 48px;

  @media screen and (max-width: $breakpoint-mobile) {
    flex-direction: column;
  }
}

.about,
.help {
  flex: 1;
  padding: 40px 0;
}

.about {
  @media screen and (max-width: $breakpoint-mobile) {
    margin-bottom: 0px;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.about-para {
  @media screen and (max-width: $breakpoint-mobile) {
    margin-bottom: 0;
  }
}

.help {
  background-color: var(--blue);
  color: var(--white);
  border-radius: var(--border-radius);
  padding: 40px;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (max-width: $breakpoint-tablet-landscape) {
    align-items: stretch;
  }

  // @media screen and (max-width: $breakpoint-tablet-portrait) {
  //   margin-left: -48px;
  //   margin-right: -48px;
  //   border-radius: 0;
  // }
}

.help-list {
  padding: 0 16px;
  margin-bottom: 24px;
}

.button-link--coffee {
  display: inline-block;
  background-color: var(--yellow);
  color: var(--blue);
  padding: 8px 16px;
  text-align: center;
}

.button-link--coffee:hover {
  background-color: var(--white);
  color: var(--blue);
}

.experience-section {
  background-color: var(--green);
  color: var(--white);
  padding: 24px 100px;

  @media screen and (max-width: $breakpoint-mobile) {
    padding-right: 16px;
    padding-left: 16px;
  }
}

.experience-title {
  text-align: center;
  font-size: 16px;
  margin-top: 16px;
  font-weight: normal;
}

.logo-list {
  list-style: none;
  max-width: 1400px;
  margin: 0 auto;
  margin-bottom: 16px;
  padding: 0;
  display: flex;
  justify-content: space-around;
  gap: 24px;

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    flex-direction: column;
    align-items: center;
    gap: 48px;
  }
}

.experience-logo {
  background-repeat: no-repeat;
}

.skyscanner {
  background-image: url("./logo-skyscanner.svg");
  height: 50px;
  width: 295px;
}

.atlassian {
  background-image: url("./logo-atlassian.svg");
  height: 50px;
  width: 307px;
}

.whereby {
  background-image: url("./logo-whereby.svg");
  height: 50px;
  width: 190px;
}

/* Testimonials */
.testimonials-section {
  margin-bottom: 48px;
}

.testimonials-intro {
  width: 66.66%;

  @media screen and (max-width: $breakpoint-tablet-landscape) {
    width: 100%;
    margin-top: 8px;
  }
}

.testimonials-list {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 32px;
  justify-content: space-between;

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    flex-direction: column;
  }
}

.testimonials-list-item {
  margin: 0;
  background-color: var(--brown);
  padding: 40px;
  border-radius: var(--border-radius);
  font-weight: bold;
  line-height: 28px;
  color: var(--white);
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: url("./quote.png");
  background-repeat: no-repeat;
  background-position: 20px 20px;
  background-size: 112px;
}

.quote {
  margin: 0 0 20px 0;
}

.author {
  padding-top: 24px;
  border-top: 1px solid var(--white);
}

.author,
.company {
  display: block;
  font-style: normal;
}

.company {
  font-weight: 300;
  font-size: 14px;
}

/* General */
.sub-title {
  font-family: var(--font-family-title);
  font-size: 60px;
  line-height: 60px;
  font-weight: 500;
  margin: 0;

  @media screen and (max-width: $breakpoint-mobile) {
    font-size: 40px;
    line-height: 40px;
  }
}

.heading {
  font-family: var(--font-family-title);
  font-size: 28px;
  font-weight: 500;
  padding: 0 0 16px 0;
  margin: 0;
  border-bottom: 1px solid var(--blue);
}

.heading-alt {
  border-color: var(--white);
}

.max-width {
  margin: 0 auto;
  max-width: 1200px;
  padding: 48px;

  @media screen and (max-width: $breakpoint-tablet-landscape) {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  @media screen and (max-width: $breakpoint-mobile) {
    padding: 24px;
  }
}

/* Contact */
.contact-section {
  background-color: var(--blue);
  color: var(--white);

  // @media screen and (max-width: $breakpoint-mobile) {
  //   padding: 18px;
  // }
}

.contact-content {
  margin: 0 auto;
  padding: 50px;
  max-width: 1400px;
  display: flex;
  gap: 30px;

  @media screen and (max-width: $breakpoint-mobile) {
    padding: 24px;
  }
}

.contact-row {
  flex: 1;

  @media screen and (max-width: $breakpoint-mobile) {
    margin: 0;
  }
}

.field-label {
  font-size: 12px;
  color: var(--yellow);
  display: block;
}

.field {
  font-family: var(--font-family-body);
  padding: 8px;
  font-size: 16px;
  border-radius: var(--border-radius-xs);
  border: 1px solid var(--white);
  background-color: var(--blue);
  color: var(--white);
  display: block;
  margin-bottom: 18px;
}

.contact-form {
  width: 100%;
  max-width: 500px;

  @media screen and (max-width: $breakpoint-mobile) {
    max-width: 100%;
  }
}

.firstname,
.surname {
  width: 100%;
}

.email,
.message {
  width: 100%;
}

.message {
  height: 200px;
}

.name-group {
  display: flex;
  gap: 32px;
}

.firstname-group,
.surname-group {
  flex: 1;
}

.submit-button {
  font-family: var(--font-family-body);
  font-weight: normal;
  font-size: 18px;
  background-color: var(--yellow);
  color: var(--blue);
  padding: 8px 16px;
  border-radius: var(--border-radius-sm);
  border: 0;
  cursor: pointer;
}

.submit-button:hover {
  background-color: var(--white);
  color: var(--blue);
}

.photo {
  border-radius: var(--border-radius);

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    display: none;
  }
}
